body {
  height: 100vh;
  color: #fff;
  text-align: center;
  background: linear-gradient(124deg, #5487ef 0%, #d146e0 23%, #f43eab 63%, #ff3f5a 100%) 0 0 / cover no-repeat fixed;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  overflow: scroll;
}

h1, h3, h4 {
  background: linear-gradient(124deg, #5487ef 0%, #d146e0 23%, #f43eab 63%, #ff3f5a 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

h3, p {
  color: #000;
}

p {
  font-size: .8em;
}

.scrollDown p {
  color: #fff;
}

.lineContainer {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (max-width: 968px) {
  svg {
    width: 100%;
  }
}

@media screen and (min-width: 968px) {
  svg {
    height: 100%;
  }
}

.scrollWrapper {
  height: 200vh;
}

.form {
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em 1em;
  display: flex;
}

.form form {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#canvas {
  width: 30vh;
  height: 40vh;
}

.form .imgContainer {
  width: 30vh;
  height: 40vh;
  cursor: pointer;
  position: relative;
}

.imgContainer img {
  width: 100%;
  height: 100%;
  visibility: hidden;
}

.imgContainer img.visible {
  visibility: visible;
}

.imgContainer svg, .imgContainer img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.form input#imgInp {
  visibility: hidden;
}

h4 {
  margin-bottom: 0;
  padding-top: 1em;
  line-height: 1.1em;
}

ul {
  color: #000;
  justify-content: center;
  align-items: center;
  padding: 0;
  list-style: none;
  display: flex;
}

li {
  cursor: pointer;
  border: 2px solid #000;
  margin: 0 1em;
  padding: 1em;
  font-size: .8em;
  font-weight: 600;
}

li.active, #uploadImage, #downloadImage {
  color: #fff;
  background: linear-gradient(124deg, #d146e0 0%, #f43eab 63%, #ff3f5a 100%);
  border: none;
}

#uploadImage, #downloadImage {
  cursor: pointer;
  grid-gap: .5em;
  grid-template-columns: 2em 1fr;
  align-items: center;
  padding: 1em 2em;
  font-size: .9em;
  font-weight: bold;
  display: grid;
}

.uploadingAnimation {
  animation: pulse1 1.5s ease-in-out infinite;
}

.displayNone {
  display: none !important;
}

.displayFlex {
  display: grid !important;
}

a, a:hover, a:link, a:active {
  font-size: .8em;
  font-weight: bold;
  text-decoration: none;
}

.skeletonLoader {
  width: 30vh;
  height: 40vh;
  z-index: 9999;
  background: #d146e0 linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(209, 70, 224, .5) 23%, rgba(244, 62, 171, .8) 53%, rgba(255, 63, 90, 0) 100%) 0 0 / 40vh 50vh no-repeat;
  animation-name: placeholderShimmer;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  display: inline-block;
  position: absolute;
}

.frameSelect {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: .5em;
  padding: 0 2em;
  display: flex;
}

.frame {
  margin: 0;
}

.formControl {
  justify-content: center;
  align-items: center;
  gap: .2em;
  display: flex;
}

.formControl label {
  color: #000;
  font-size: .8em;
  font-weight: bold;
}

.errorMessage, .errorMessageFilter {
  width: 100%;
  color: #f53fa1;
  font-size: .8em;
  font-weight: bold;
}

.privacyAgreement {
  padding-bottom: 1em;
}

.qrCode {
  width: 30vh;
  height: auto;
  padding: 2em 0;
}

.qrCode img {
  max-width: 100%;
}

@keyframes pulse1 {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  30% {
    opacity: 1;
    transform: scale(.8);
  }

  60% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes pulse2 {
  0% {
    opacity: 0;
    transform: scale(1);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(6);
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -500px 0;
  }

  100% {
    background-position: 500px 0;
  }
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(100px);
  }
}

svg #wheel {
  animation: scroll 1.5s infinite;
}

.scrollDown {
  width: 30px;
  height: auto;
  z-index: -1;
  flex-direction: column;
  align-items: center;
  margin-left: -15px;
  display: flex;
  position: fixed;
  bottom: 2em;
  left: 50%;
}

.scrollDown svg {
  max-width: 100%;
}

/*# sourceMappingURL=index.de0c9caa.css.map */
