body {
    background: linear-gradient(124deg, rgba(84,135,239,1) 0%, rgba(209,70,224,1) 23%, rgba(244,62,171,1) 63%, rgba(255,63,90,1) 100%);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 100vh;
    overflow: scroll;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: white;
    text-align: center;
    margin: 0;
}

h1, h3, h4 {
    background: linear-gradient(124deg, rgba(84,135,239,1) 0%, rgba(209,70,224,1) 23%, rgba(244,62,171,1) 63%, rgba(255,63,90,1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
     -webkit-text-fill-color: transparent;
}

h3, p {
    color: black;
}

p {
    font-size: 0.8em;
}

.scrollDown p {
    color: white;
}

.lineContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

@media screen and (max-width: 968px) {
    svg {
        width: 100%;
    }
}

@media screen and (min-width: 968px) {
    svg {
        height: 100%;
    }
}

.scrollWrapper {
    height: 200vh;
}

.form {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    width: 100%;
    background: white;
    padding: 2em 1em;
}

.form form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#canvas {
    width: 30vh;
    height: 40vh;
}

.form .imgContainer {
    width: 30vh;
    height: 40vh;
    position: relative;
    cursor: pointer;
}

.imgContainer img {
    width: 100%;
    height: 100%;
    visibility: hidden;
}

.imgContainer img.visible {
    visibility: visible;
}

.imgContainer svg,
.imgContainer img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.hidden {
    visibility: hidden;
}

.visible {
    visibility: visible;
}

.form input#imgInp {
    visibility: hidden;
}

h4 {
    padding-top: 1em;
    margin-bottom:0;
    line-height: 1.1em;
}

ul {
    list-style: none;
    padding: 0;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

li {
    margin: 0 1em;
    border: 2px solid black;
    padding: 1em;
    cursor: pointer;
    font-size: 0.8em;
    font-weight: 600;
}

li.active,
#uploadImage,
#downloadImage {
    background: linear-gradient(124deg, rgba(209,70,224,1) 0%, rgba(244,62,171,1) 63%, rgba(255,63,90,1) 100%);
    color: white;
    border: none; 
}

#uploadImage,
#downloadImage {
    cursor: pointer;
    display: grid;
    align-items: center;
    grid-template-columns: 2em 1fr;
    grid-gap: 0.5em;
    padding: 1em 2em;
    font-size: 0.9em;
    font-weight: bold;
}

.uploadingAnimation {
    animation: pulse1 1.5s ease-in-out infinite;
}

.displayNone {
    display: none !important;
}

.displayFlex {
    display: grid !important;
}

a, a:hover, a:link, a:active {
    text-decoration: none;
    font-weight: bold;
    font-size: 0.8em;
}

.skeletonLoader {
    position: absolute;
    width: 30vh;
    height: 40vh;
    z-index: 9999;
    background: rgba(209,70,224,1);
    background-image: linear-gradient(to right,rgba(0,0,0,0) 0%, rgba(209,70,224,0.5) 23%, rgba(244,62,171,0.8) 53%, rgba(255,63,90,0) 100%);
    background-repeat: no-repeat;
    background-size: 40vh 50vh; 
    animation-duration: 2s;
    animation-fill-mode: forwards; 
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
    display: inline-block
}

.frameSelect {
    padding: 0 2em;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    justify-content: center;
    align-items: center;
}

.frame {
    margin: 0;
}

.formControl {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2em;
}

.formControl label {
    color: black;
    font-size: 0.8em;
    font-weight: bold;
}

.errorMessage,
.errorMessageFilter {
    width: 100%;
    color: #F53FA1;
    font-size: 0.8em;
    font-weight: bold;
}

.privacyAgreement {
    padding-bottom: 1em;
}

.qrCode {
    padding: 2em 0;
    width: 30vh;
    height: auto;
}

.qrCode img {
    max-width: 100%;
}

@keyframes pulse1 {
	0% {
		opacity: 0;
		transform: scale(0);
	}

	30% {
		opacity: 1;
		transform: scale(0.8);
	}

	60% {
		opacity: 1;
		transform: scale(1);
	}

	100% {
		opacity: 0;
		transform: scale(1);
	}
}

@keyframes pulse2 {
	0% {
		transform: scale(1, 1);
		opacity: 0;
	}

	50% {
	    opacity: 1;
	}

	100% {
	    transform: scale(6, 6);
	    opacity: 0;
		}
	}

@keyframes placeholderShimmer {
    0% {
        background-position: -500px 0;
    }
    
    100% {
        background-position: 500px 0; 
    }
}

@keyframes scroll {
	0% {
		transform: translateY(0);
	}
	30% {
		transform: translateY(100px);
	}
}

svg #wheel {
	animation: scroll ease 1.5s infinite;
}

.scrollDown {
    position: fixed;
    width: 30px;
    height: auto;
    bottom: 2em;
    left: 50%;
    margin-left: -15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: -1;
}

.scrollDown svg {
    max-width: 100%;
}